<template>
  <v-row justify="center">
    <v-col
      cols="12"
      md="10"
      lg="8"
    >
      <page-title
        title="Add an Airfield"
        subtitle="Are we missing your favourite airfield, or you run/own an airfield which isn't in the list? Fill in the airfield details and we'll get you added to the list asap!"
      />

      <v-card>
        <div v-if="hasSubmitted">
          <v-card-title> Airfield Submitted </v-card-title>
          <v-card-text>
            <p class="text-body-1">
              Thank you for sending the details of this airfield, it will be
              added to the list shortly.
            </p>
            <p class="text-body-1">
              To add another airfield just click the reset button to start
              again.
            </p>
            <v-row
              justify="center"
              class="mt-8 mb-1"
            >
              <v-btn
                color="primary"
                rounded
                @click="resetForm"
              >
                Reset
              </v-btn>
            </v-row>
          </v-card-text>
        </div>

        <v-card-text v-if="!hasSubmitted">
          <validation-observer ref="observer">
            <v-form
              ref="form"
              :disabled="isSubmitting"
              lazy-validation
              @submit.prevent="onSubmit"
            >
              <h3 class="mb-3">
                Details
              </h3>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <v-text-field
                      outlined
                      label="Airfield Name"
                      required
                      v-model="airfield.name"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="length:4"
                  >
                    <v-text-field
                      outlined
                      label="ICAO Code"
                      v-model="airfield.icaoCode"
                      hint="Optional, 4 characters assigned by ICAO"
                      persistent-hint
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <v-text-field
                      outlined
                      label="City, and County"
                      required
                      v-model="airfield.address"
                      hint="Nearest city and county, eg. 'Winchester, Hampshire', 'Swansea, Wales'"
                      persistent-hint
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <v-select
                      outlined
                      single-line
                      label="Airfield Size"
                      v-model="airfield.size"
                      :items="airfieldSizes"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="url"
                  >
                    <v-text-field
                      outlined
                      label="Website URL"
                      type="url"
                      v-model="airfield.websiteUrl"
                      placeholder="https://example-airfield.com"
                      hint="Optional"
                      persistent-hint
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="url"
                  >
                    <v-text-field
                      outlined
                      label="Pilot Briefing URL"
                      type="url"
                      v-model="airfield.pilotBriefingUrl"
                      placeholder="https://example-airfield.com/pilots-briefing"
                      hint="Optional"
                      persistent-hint
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-divider class="my-4" />
              <h3 class="mb-3">
                Runways
              </h3>

              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|integer"
                  >
                    <v-text-field
                      outlined
                      label="Maximum Runway Length"
                      required
                      type="number"
                      min="100"
                      max="5000"
                      v-model="airfield.runwayMaxLength"
                      placeholder=""
                      hint="in Meters"
                      persistent-hint
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <p
                      :class="
                        (errors.length > 0 ? 'error--text' : '') +
                          ' btn-group-label mb-0'
                      "
                    >
                      Surfaces
                    </p>
                    <v-btn-toggle
                      v-model="airfield.runwaySurfaces"
                      rounded
                      multiple
                      dense
                      color="secondary"
                      class="my-2"
                    >
                      <v-btn
                        v-for="(value, i) in runwaySurfaces"
                        :key="value"
                        :value="value"
                        :class="
                          i === 0
                            ? 'ps-3'
                            : i === runwaySurfaces.length - 1
                              ? 'pe-3'
                              : ''
                        "
                      >
                        {{ value }}
                      </v-btn>
                    </v-btn-toggle>
                    <div
                      v-if="errors.length > 0"
                      class="v-messages error--text ms-3"
                      role="alert"
                    >
                      <div
                        class="v-messages__message"
                        v-for="(error, i) in errors"
                        :key="i"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    rules="integer"
                  >
                    <p class="btn-group-label mt-4 mb-0">
                      Winter Conditions
                    </p>
                    <v-btn-toggle
                      v-model="airfield.winterDrainage"
                      rounded
                      dense
                      color="secondary"
                      :error-messages="errors"
                      class="my-2"
                    >
                      <v-btn
                        v-for="(value, i) in winterDrainages"
                        :key="value"
                        :value="i"
                        :class="
                          i === 0
                            ? 'ps-3'
                            : i === winterDrainages.length - 1
                              ? 'pe-3'
                              : ''
                        "
                      >
                        {{ value }}
                      </v-btn>
                    </v-btn-toggle>
                    <div
                      v-if="errors.length > 0"
                      class="v-messages error--text ms-3"
                      role="alert"
                    >
                      <div
                        class="v-messages__message"
                        v-for="(error, i) in errors"
                        :key="i"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </validation-provider>
                </v-col>
              </v-row>

              <v-divider class="my-4" />
              <h3 class="mb-3">
                PPR
              </h3>

              <v-row>
                <v-col
                  cols="5"
                  sm="4"
                  md="3"
                  align-self="start"
                >
                  <v-switch
                    label="PPR required?"
                    v-model="airfield.ppr"
                    color="secondary"
                    class="mt-1"
                    hide-details
                  />
                </v-col>
                <v-col
                  cols="7"
                  sm="8"
                  md="9"
                  align-self="start"
                  v-show="airfield.ppr"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="`${airfield.ppr ? 'required' : ''}`"
                  >
                    <v-btn-toggle
                      v-model="airfield.pprTypes"
                      rounded
                      multiple
                      dense
                      color="secondary"
                      class="mt-0 mb-2"
                    >
                      <v-btn
                        v-for="(value, i) in pprTypes"
                        :key="value"
                        :value="value"
                        :class="
                          i === 0
                            ? 'ps-3'
                            : i === pprTypes.length - 1
                              ? 'pe-3'
                              : ''
                        "
                      >
                        {{ value }}
                      </v-btn>
                    </v-btn-toggle>
                    <div
                      v-if="errors.length > 0"
                      class="v-messages error--text ms-3"
                      role="alert"
                    >
                      <div
                        class="v-messages__message"
                        v-for="(error, i) in errors"
                        :key="i"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </validation-provider>
                </v-col>
              </v-row>

              <v-divider class="my-4" />
              <h3 class="mb-3">
                Radio Service
              </h3>

              <v-row>
                <v-col
                  cols="5"
                  sm="4"
                  md="3"
                  align-self="start"
                >
                  <v-switch
                    label="Radio Service"
                    v-model="airfield.radioService"
                    color="secondary"
                    class="mt-1"
                    hide-details
                  />
                </v-col>
                <v-col
                  cols="7"
                  sm="8"
                  md="9"
                  align-self="start"
                  v-show="airfield.radioService"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="`${airfield.radioService ? 'required' : ''}`"
                  >
                    <v-btn-toggle
                      v-model="airfield.radioServiceType"
                      rounded
                      dense
                      color="secondary"
                      class="mt-0 mb-2"
                    >
                      <v-btn
                        v-for="(value, i) in radioServiceTypes"
                        :key="value"
                        :value="value"
                        :class="
                          i === 0
                            ? 'ps-3'
                            : i === radioServiceTypes.length - 1
                              ? 'pe-3'
                              : ''
                        "
                      >
                        {{ value }}
                      </v-btn>
                    </v-btn-toggle>
                    <div
                      v-if="errors.length > 0"
                      class="v-messages error--text ms-3"
                      role="alert"
                    >
                      <div
                        class="v-messages__message"
                        v-for="(error, i) in errors"
                        :key="i"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </validation-provider>
                </v-col>
              </v-row>

              <v-divider class="my-4" />
              <h3 class="mb-3">
                Facilities
              </h3>

              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="3"
                  align-self="start"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules=""
                  >
                    <v-switch
                      label="Food/Drink"
                      v-model="airfield.foodDrink"
                      color="secondary"
                      class="mt-1"
                      hide-details
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="8"
                  md="9"
                  align-self="start"
                  v-show="airfield.foodDrink"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules=""
                  >
                    <v-text-field
                      outlined
                      dense
                      label="Food/Drink Details"
                      v-model="airfield.foodDrinkText"
                      hint="Type of food/drink premises, eg. 'Cafe onsite', 'Restaurant nearby', 'Pub round the corner'"
                      persistent-hint
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="3"
                  align-self="start"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules=""
                  >
                    <v-switch
                      label="Accommodation"
                      v-model="airfield.accommodation"
                      color="secondary"
                      class="mt-1"
                      hide-details
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="8"
                  md="9"
                  align-self="start"
                  v-show="airfield.accommodation"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules=""
                  >
                    <v-text-field
                      outlined
                      dense
                      label="Accommodation Details"
                      v-model="airfield.accommodationText"
                      hint="Type of accommodation, eg. 'Camping onsite', 'B&amp;B nearby', 'Hotel in nearby town'"
                      persistent-hint
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="3"
                  align-self="start"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules=""
                  >
                    <v-switch
                      label="Transportation"
                      v-model="airfield.transportation"
                      color="secondary"
                      class="mt-1"
                      hide-details
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="8"
                  md="9"
                  align-self="start"
                  v-show="airfield.transportation"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules=""
                  >
                    <v-text-field
                      outlined
                      dense
                      label="Transportation Details"
                      v-model="airfield.transportationText"
                      hint="Type of transportation, eg. 'Bicycles', 'Local taxis', 'Cars for hire'"
                      persistent-hint
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  align-self="start"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules=""
                  >
                    <v-switch
                      label="Toilets"
                      v-model="airfield.toilets"
                      color="secondary"
                      class="mt-1"
                      hide-details
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  align-self="start"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules=""
                  >
                    <v-switch
                      label="Club House"
                      v-model="airfield.clubHouse"
                      color="secondary"
                      class="mt-1"
                      hide-details
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="3"
                  align-self="start"
                >
                  <v-switch
                    label="Fuel"
                    v-model="airfield.fuel"
                    color="secondary"
                    class="mt-1"
                    hide-details
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="8"
                  md="9"
                  align-self="start"
                  v-show="airfield.fuel"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="`${airfield.fuel ? 'required' : ''}`"
                  >
                    <v-btn-toggle
                      v-model="airfield.fuelTypes"
                      multiple
                      rounded
                      dense
                      color="secondary"
                      :error-messages="errors"
                      class="my-2"
                    >
                      <v-btn
                        v-for="(value, i) in fuelTypes"
                        :key="value"
                        :value="value"
                        :class="
                          i === 0
                            ? 'ps-3'
                            : i === fuelTypes.length - 1
                              ? 'pe-3'
                              : ''
                        "
                      >
                        {{ value }}
                      </v-btn>
                    </v-btn-toggle>
                    <div
                      v-if="errors.length > 0"
                      class="v-messages error--text ms-3"
                      role="alert"
                    >
                      <div
                        class="v-messages__message"
                        v-for="(error, i) in errors"
                        :key="i"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="3"
                  align-self="start"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules=""
                  >
                    <v-switch
                      label="Maintenance"
                      v-model="airfield.maintenance"
                      color="secondary"
                      class="mt-1"
                      hide-details
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="8"
                  md="9"
                  align-self="start"
                  v-show="airfield.maintenance"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules=""
                  >
                    <v-text-field
                      outlined
                      dense
                      label="Maintenance Details"
                      v-model="airfield.maintenanceText"
                      hint="Type of maintenace business onsite"
                      persistent-hint
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="3"
                  align-self="start"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules=""
                  >
                    <v-switch
                      label="Flight School"
                      v-model="airfield.flightSchool"
                      color="secondary"
                      class="mt-1"
                      hide-details
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  sm="8"
                  md="9"
                  align-self="start"
                  v-show="airfield.flightSchool"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules=""
                  >
                    <v-text-field
                      outlined
                      dense
                      label="Flight School Details"
                      v-model="airfield.flightSchoolText"
                      hint="Type of flight school(s) onsite, eg. 'PPL', 'Microlights', 'Gyrocopters', etc."
                      persistent-hint
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="3"
                  align-self="start"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules=""
                  >
                    <v-switch
                      label="Instrument Approaches"
                      v-model="airfield.instrumentApproaches"
                      color="secondary"
                      class="mt-1"
                      hide-details
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-divider class="my-4" />
              <h3 class="mb-3">
                Reasons to Visit
              </h3>

              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="integer"
                  >
                    <p
                      :class="
                        (errors.length > 0 ? 'error--text' : '') +
                          ' btn-group-label mb-0'
                      "
                    >
                      Scenery / Landscape
                    </p>
                    <v-btn-toggle
                      v-model="airfield.scenery"
                      rounded
                      dense
                      color="secondary"
                      :error-messages="errors"
                      class="my-2"
                    >
                      <v-btn
                        v-for="(value, i) in sceneryRatings"
                        :key="value"
                        :value="i + 1"
                        :class="
                          i === 0
                            ? 'ps-3'
                            : i === sceneryRatings.length - 1
                              ? 'pe-3'
                              : ''
                        "
                      >
                        {{ value }}
                      </v-btn>
                    </v-btn-toggle>
                    <div
                      v-if="errors.length > 0"
                      class="v-messages error--text"
                      role="alert"
                    >
                      <div
                        class="v-messages__message"
                        v-for="(error, i) in errors"
                        :key="i"
                      >
                        {{ error }}
                      </div>
                    </div>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="integer"
                  >
                    <p
                      :class="
                        (errors.length > 0 ? 'error--text' : '') +
                          ' btn-group-label mb-0'
                      "
                    >
                      Busyness
                    </p>
                    <v-btn-toggle
                      v-model="airfield.busyness"
                      rounded
                      dense
                      color="secondary"
                      :error-messages="errors"
                      class="my-2"
                    >
                      <v-btn
                        v-for="(value, i) in busynessRatings"
                        :key="value"
                        :value="i + 1"
                        :class="
                          i === 0
                            ? 'ps-3'
                            : i === busynessRatings.length - 1
                              ? 'pe-3'
                              : ''
                        "
                      >
                        {{ value }}
                      </v-btn>
                    </v-btn-toggle>
                    <div
                      v-if="errors.length > 0"
                      class="v-messages error--text"
                      role="alert"
                    >
                      <div
                        class="v-messages__message"
                        v-for="(error, i) in errors"
                        :key="i"
                      >
                        {{ error }}
                      </div>
                    </div>
                    <div
                      v-else
                      class="v-messages"
                    >
                      <div class="v-messages__message">
                        On a typical decent flying weather day.
                      </div>
                    </div>
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    rules=""
                  >
                    <v-text-field
                      outlined
                      label="Things to see"
                      v-model="airfield.reasonsToVisit"
                      hint="Optional - unique things or attractions to see here, either from the air, or on the ground."
                      persistent-hint
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-divider class="my-4" />

              <v-row
                justify="center"
                class="mt-4 mb-1"
              >
                <v-btn
                  color="primary"
                  rounded
                  type="submit"
                  :loading="isSubmitting"
                >
                  Send
                </v-btn>
              </v-row>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import { integer, length, required } from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from "vee-validate";
import {
  runwaySurfaces,
  winterDrainages,
  pprTypes,
  radioServiceTypes,
  airfieldSizes,
  fuelTypes,
  sceneryRatings,
  busynessRatings,
} from "@/data/constants";

export default {
  name: "Add",

  created() {
    setInteractionMode("eager");

    extend("required", {
      ...required,
      message: "Required",
    });
    extend("integer", {
      ...integer,
      message: "Must be a number",
    });
    extend("length", {
      ...length,
      message: "Must be {length} characters",
    });
    extend("url", {
      validate(value) {
        if (value) {
          return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
            value
          );
        }
        return false;
      },
      message: "Must be a valid web address",
    });
  },

  components: {
    PageTitle,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      isSubmitting: false,
      hasSubmitted: false,
      runwaySurfaces,
      winterDrainages,
      pprTypes,
      radioServiceTypes,
      airfieldSizes,
      fuelTypes,
      sceneryRatings,
      busynessRatings,
      airfield: this.newAirfield(),
    };
  },

  methods: {
    newAirfield() {
      return {
        name: null,
        icaoCode: null,
        address: null,
        size: null,
        websiteUrl: null,
        pilotBriefingUrl: null,
        runwayMaxLength: null,
        runwaySurfaces: [],
        winterDrainage: null,
        ppr: false,
        pprTypes: [],
        radioService: false,
        radioServiceType: null,
        foodDrink: false,
        foodDrinkText: null,
        accommodation: false,
        accommodationText: null,
        transportation: false,
        transportationText: null,
        toilets: false,
        clubHouse: false,
        fuel: false,
        fuelTypes: [],
        maintenance: false,
        maintenanceText: null,
        flightSchool: false,
        flightSchoolText: null,
        instrumentApproaches: false,
        scenery: null,
        busyness: null,
        reasonsToVisit: null,
      };
    },
    resetForm() {
      this.airfield = this.newAirfield();
      this.hasSubmitted = false;
    },
    onSubmit: function () {
      this.isSubmitting = true;
      this.$refs.observer
        .validate()
        .then(async (isValid) => {
          if (isValid) {
            await this.$store.dispatch("airfields/add", this.airfield);

            this.hasSubmitted = true;
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn-toggle .v-btn {
  text-transform: none !important;
}
</style>
