var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"10","lg":"8"}},[_c('page-title',{attrs:{"title":"Add an Airfield","subtitle":"Are we missing your favourite airfield, or you run/own an airfield which isn't in the list? Fill in the airfield details and we'll get you added to the list asap!"}}),_c('v-card',[(_vm.hasSubmitted)?_c('div',[_c('v-card-title',[_vm._v(" Airfield Submitted ")]),_c('v-card-text',[_c('p',{staticClass:"text-body-1"},[_vm._v(" Thank you for sending the details of this airfield, it will be added to the list shortly. ")]),_c('p',{staticClass:"text-body-1"},[_vm._v(" To add another airfield just click the reset button to start again. ")]),_c('v-row',{staticClass:"mt-8 mb-1",attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":_vm.resetForm}},[_vm._v(" Reset ")])],1)],1)],1):_vm._e(),(!_vm.hasSubmitted)?_c('v-card-text',[_c('validation-observer',{ref:"observer"},[_c('v-form',{ref:"form",attrs:{"disabled":_vm.isSubmitting,"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('h3',{staticClass:"mb-3"},[_vm._v(" Details ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Airfield Name","required":"","error-messages":errors},model:{value:(_vm.airfield.name),callback:function ($$v) {_vm.$set(_vm.airfield, "name", $$v)},expression:"airfield.name"}})]}}],null,false,1086769194)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"length:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"ICAO Code","hint":"Optional, 4 characters assigned by ICAO","persistent-hint":"","error-messages":errors},model:{value:(_vm.airfield.icaoCode),callback:function ($$v) {_vm.$set(_vm.airfield, "icaoCode", $$v)},expression:"airfield.icaoCode"}})]}}],null,false,2075749062)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"City, and County","required":"","hint":"Nearest city and county, eg. 'Winchester, Hampshire', 'Swansea, Wales'","persistent-hint":"","error-messages":errors},model:{value:(_vm.airfield.address),callback:function ($$v) {_vm.$set(_vm.airfield, "address", $$v)},expression:"airfield.address"}})]}}],null,false,2402813529)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"outlined":"","single-line":"","label":"Airfield Size","items":_vm.airfieldSizes,"error-messages":errors},model:{value:(_vm.airfield.size),callback:function ($$v) {_vm.$set(_vm.airfield, "size", $$v)},expression:"airfield.size"}})]}}],null,false,2504400926)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Website URL","type":"url","placeholder":"https://example-airfield.com","hint":"Optional","persistent-hint":"","error-messages":errors},model:{value:(_vm.airfield.websiteUrl),callback:function ($$v) {_vm.$set(_vm.airfield, "websiteUrl", $$v)},expression:"airfield.websiteUrl"}})]}}],null,false,3913575256)})],1),_c('v-col',[_c('validation-provider',{attrs:{"rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Pilot Briefing URL","type":"url","placeholder":"https://example-airfield.com/pilots-briefing","hint":"Optional","persistent-hint":"","error-messages":errors},model:{value:(_vm.airfield.pilotBriefingUrl),callback:function ($$v) {_vm.$set(_vm.airfield, "pilotBriefingUrl", $$v)},expression:"airfield.pilotBriefingUrl"}})]}}],null,false,1949123837)})],1)],1),_c('v-divider',{staticClass:"my-4"}),_c('h3',{staticClass:"mb-3"},[_vm._v(" Runways ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Maximum Runway Length","required":"","type":"number","min":"100","max":"5000","placeholder":"","hint":"in Meters","persistent-hint":"","error-messages":errors},model:{value:(_vm.airfield.runwayMaxLength),callback:function ($$v) {_vm.$set(_vm.airfield, "runwayMaxLength", $$v)},expression:"airfield.runwayMaxLength"}})]}}],null,false,3485643979)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{class:(errors.length > 0 ? 'error--text' : '') +
                        ' btn-group-label mb-0'},[_vm._v(" Surfaces ")]),_c('v-btn-toggle',{staticClass:"my-2",attrs:{"rounded":"","multiple":"","dense":"","color":"secondary"},model:{value:(_vm.airfield.runwaySurfaces),callback:function ($$v) {_vm.$set(_vm.airfield, "runwaySurfaces", $$v)},expression:"airfield.runwaySurfaces"}},_vm._l((_vm.runwaySurfaces),function(value,i){return _c('v-btn',{key:value,class:i === 0
                          ? 'ps-3'
                          : i === _vm.runwaySurfaces.length - 1
                            ? 'pe-3'
                            : '',attrs:{"value":value}},[_vm._v(" "+_vm._s(value)+" ")])}),1),(errors.length > 0)?_c('div',{staticClass:"v-messages error--text ms-3",attrs:{"role":"alert"}},_vm._l((errors),function(error,i){return _c('div',{key:i,staticClass:"v-messages__message"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()]}}],null,false,675188547)}),_c('validation-provider',{attrs:{"rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('p',{staticClass:"btn-group-label mt-4 mb-0"},[_vm._v(" Winter Conditions ")]),_c('v-btn-toggle',{staticClass:"my-2",attrs:{"rounded":"","dense":"","color":"secondary","error-messages":errors},model:{value:(_vm.airfield.winterDrainage),callback:function ($$v) {_vm.$set(_vm.airfield, "winterDrainage", $$v)},expression:"airfield.winterDrainage"}},_vm._l((_vm.winterDrainages),function(value,i){return _c('v-btn',{key:value,class:i === 0
                          ? 'ps-3'
                          : i === _vm.winterDrainages.length - 1
                            ? 'pe-3'
                            : '',attrs:{"value":i}},[_vm._v(" "+_vm._s(value)+" ")])}),1),(errors.length > 0)?_c('div',{staticClass:"v-messages error--text ms-3",attrs:{"role":"alert"}},_vm._l((errors),function(error,i){return _c('div',{key:i,staticClass:"v-messages__message"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()]}}],null,false,3048216909)})],1)],1),_c('v-divider',{staticClass:"my-4"}),_c('h3',{staticClass:"mb-3"},[_vm._v(" PPR ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"5","sm":"4","md":"3","align-self":"start"}},[_c('v-switch',{staticClass:"mt-1",attrs:{"label":"PPR required?","color":"secondary","hide-details":""},model:{value:(_vm.airfield.ppr),callback:function ($$v) {_vm.$set(_vm.airfield, "ppr", $$v)},expression:"airfield.ppr"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.airfield.ppr),expression:"airfield.ppr"}],attrs:{"cols":"7","sm":"8","md":"9","align-self":"start"}},[_c('validation-provider',{attrs:{"rules":("" + (_vm.airfield.ppr ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-btn-toggle',{staticClass:"mt-0 mb-2",attrs:{"rounded":"","multiple":"","dense":"","color":"secondary"},model:{value:(_vm.airfield.pprTypes),callback:function ($$v) {_vm.$set(_vm.airfield, "pprTypes", $$v)},expression:"airfield.pprTypes"}},_vm._l((_vm.pprTypes),function(value,i){return _c('v-btn',{key:value,class:i === 0
                          ? 'ps-3'
                          : i === _vm.pprTypes.length - 1
                            ? 'pe-3'
                            : '',attrs:{"value":value}},[_vm._v(" "+_vm._s(value)+" ")])}),1),(errors.length > 0)?_c('div',{staticClass:"v-messages error--text ms-3",attrs:{"role":"alert"}},_vm._l((errors),function(error,i){return _c('div',{key:i,staticClass:"v-messages__message"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()]}}],null,false,3702372314)})],1)],1),_c('v-divider',{staticClass:"my-4"}),_c('h3',{staticClass:"mb-3"},[_vm._v(" Radio Service ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"5","sm":"4","md":"3","align-self":"start"}},[_c('v-switch',{staticClass:"mt-1",attrs:{"label":"Radio Service","color":"secondary","hide-details":""},model:{value:(_vm.airfield.radioService),callback:function ($$v) {_vm.$set(_vm.airfield, "radioService", $$v)},expression:"airfield.radioService"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.airfield.radioService),expression:"airfield.radioService"}],attrs:{"cols":"7","sm":"8","md":"9","align-self":"start"}},[_c('validation-provider',{attrs:{"rules":("" + (_vm.airfield.radioService ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-btn-toggle',{staticClass:"mt-0 mb-2",attrs:{"rounded":"","dense":"","color":"secondary"},model:{value:(_vm.airfield.radioServiceType),callback:function ($$v) {_vm.$set(_vm.airfield, "radioServiceType", $$v)},expression:"airfield.radioServiceType"}},_vm._l((_vm.radioServiceTypes),function(value,i){return _c('v-btn',{key:value,class:i === 0
                          ? 'ps-3'
                          : i === _vm.radioServiceTypes.length - 1
                            ? 'pe-3'
                            : '',attrs:{"value":value}},[_vm._v(" "+_vm._s(value)+" ")])}),1),(errors.length > 0)?_c('div',{staticClass:"v-messages error--text ms-3",attrs:{"role":"alert"}},_vm._l((errors),function(error,i){return _c('div',{key:i,staticClass:"v-messages__message"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()]}}],null,false,620331729)})],1)],1),_c('v-divider',{staticClass:"my-4"}),_c('h3',{staticClass:"mb-3"},[_vm._v(" Facilities ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","align-self":"start"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-switch',{staticClass:"mt-1",attrs:{"label":"Food/Drink","color":"secondary","hide-details":"","error-messages":errors},model:{value:(_vm.airfield.foodDrink),callback:function ($$v) {_vm.$set(_vm.airfield, "foodDrink", $$v)},expression:"airfield.foodDrink"}})]}}],null,false,2663311549)})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.airfield.foodDrink),expression:"airfield.foodDrink"}],attrs:{"cols":"12","sm":"8","md":"9","align-self":"start"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Food/Drink Details","hint":"Type of food/drink premises, eg. 'Cafe onsite', 'Restaurant nearby', 'Pub round the corner'","persistent-hint":"","error-messages":errors},model:{value:(_vm.airfield.foodDrinkText),callback:function ($$v) {_vm.$set(_vm.airfield, "foodDrinkText", $$v)},expression:"airfield.foodDrinkText"}})]}}],null,false,450184868)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","align-self":"start"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-switch',{staticClass:"mt-1",attrs:{"label":"Accommodation","color":"secondary","hide-details":"","error-messages":errors},model:{value:(_vm.airfield.accommodation),callback:function ($$v) {_vm.$set(_vm.airfield, "accommodation", $$v)},expression:"airfield.accommodation"}})]}}],null,false,24846866)})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.airfield.accommodation),expression:"airfield.accommodation"}],attrs:{"cols":"12","sm":"8","md":"9","align-self":"start"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Accommodation Details","hint":"Type of accommodation, eg. 'Camping onsite', 'B&B nearby', 'Hotel in nearby town'","persistent-hint":"","error-messages":errors},model:{value:(_vm.airfield.accommodationText),callback:function ($$v) {_vm.$set(_vm.airfield, "accommodationText", $$v)},expression:"airfield.accommodationText"}})]}}],null,false,128566160)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","align-self":"start"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-switch',{staticClass:"mt-1",attrs:{"label":"Transportation","color":"secondary","hide-details":"","error-messages":errors},model:{value:(_vm.airfield.transportation),callback:function ($$v) {_vm.$set(_vm.airfield, "transportation", $$v)},expression:"airfield.transportation"}})]}}],null,false,1597834706)})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.airfield.transportation),expression:"airfield.transportation"}],attrs:{"cols":"12","sm":"8","md":"9","align-self":"start"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Transportation Details","hint":"Type of transportation, eg. 'Bicycles', 'Local taxis', 'Cars for hire'","persistent-hint":"","error-messages":errors},model:{value:(_vm.airfield.transportationText),callback:function ($$v) {_vm.$set(_vm.airfield, "transportationText", $$v)},expression:"airfield.transportationText"}})]}}],null,false,1636612966)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","align-self":"start"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-switch',{staticClass:"mt-1",attrs:{"label":"Toilets","color":"secondary","hide-details":"","error-messages":errors},model:{value:(_vm.airfield.toilets),callback:function ($$v) {_vm.$set(_vm.airfield, "toilets", $$v)},expression:"airfield.toilets"}})]}}],null,false,3875703314)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","align-self":"start"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-switch',{staticClass:"mt-1",attrs:{"label":"Club House","color":"secondary","hide-details":"","error-messages":errors},model:{value:(_vm.airfield.clubHouse),callback:function ($$v) {_vm.$set(_vm.airfield, "clubHouse", $$v)},expression:"airfield.clubHouse"}})]}}],null,false,3208911698)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","align-self":"start"}},[_c('v-switch',{staticClass:"mt-1",attrs:{"label":"Fuel","color":"secondary","hide-details":""},model:{value:(_vm.airfield.fuel),callback:function ($$v) {_vm.$set(_vm.airfield, "fuel", $$v)},expression:"airfield.fuel"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.airfield.fuel),expression:"airfield.fuel"}],attrs:{"cols":"12","sm":"8","md":"9","align-self":"start"}},[_c('validation-provider',{attrs:{"rules":("" + (_vm.airfield.fuel ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-btn-toggle',{staticClass:"my-2",attrs:{"multiple":"","rounded":"","dense":"","color":"secondary","error-messages":errors},model:{value:(_vm.airfield.fuelTypes),callback:function ($$v) {_vm.$set(_vm.airfield, "fuelTypes", $$v)},expression:"airfield.fuelTypes"}},_vm._l((_vm.fuelTypes),function(value,i){return _c('v-btn',{key:value,class:i === 0
                          ? 'ps-3'
                          : i === _vm.fuelTypes.length - 1
                            ? 'pe-3'
                            : '',attrs:{"value":value}},[_vm._v(" "+_vm._s(value)+" ")])}),1),(errors.length > 0)?_c('div',{staticClass:"v-messages error--text ms-3",attrs:{"role":"alert"}},_vm._l((errors),function(error,i){return _c('div',{key:i,staticClass:"v-messages__message"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()]}}],null,false,2258656413)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","align-self":"start"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-switch',{staticClass:"mt-1",attrs:{"label":"Maintenance","color":"secondary","hide-details":"","error-messages":errors},model:{value:(_vm.airfield.maintenance),callback:function ($$v) {_vm.$set(_vm.airfield, "maintenance", $$v)},expression:"airfield.maintenance"}})]}}],null,false,3523991570)})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.airfield.maintenance),expression:"airfield.maintenance"}],attrs:{"cols":"12","sm":"8","md":"9","align-self":"start"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Maintenance Details","hint":"Type of maintenace business onsite","persistent-hint":"","error-messages":errors},model:{value:(_vm.airfield.maintenanceText),callback:function ($$v) {_vm.$set(_vm.airfield, "maintenanceText", $$v)},expression:"airfield.maintenanceText"}})]}}],null,false,666390299)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","align-self":"start"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-switch',{staticClass:"mt-1",attrs:{"label":"Flight School","color":"secondary","hide-details":"","error-messages":errors},model:{value:(_vm.airfield.flightSchool),callback:function ($$v) {_vm.$set(_vm.airfield, "flightSchool", $$v)},expression:"airfield.flightSchool"}})]}}],null,false,1170202066)})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.airfield.flightSchool),expression:"airfield.flightSchool"}],attrs:{"cols":"12","sm":"8","md":"9","align-self":"start"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Flight School Details","hint":"Type of flight school(s) onsite, eg. 'PPL', 'Microlights', 'Gyrocopters', etc.","persistent-hint":"","error-messages":errors},model:{value:(_vm.airfield.flightSchoolText),callback:function ($$v) {_vm.$set(_vm.airfield, "flightSchoolText", $$v)},expression:"airfield.flightSchoolText"}})]}}],null,false,995369076)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3","align-self":"start"}},[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-switch',{staticClass:"mt-1",attrs:{"label":"Instrument Approaches","color":"secondary","hide-details":"","error-messages":errors},model:{value:(_vm.airfield.instrumentApproaches),callback:function ($$v) {_vm.$set(_vm.airfield, "instrumentApproaches", $$v)},expression:"airfield.instrumentApproaches"}})]}}],null,false,4204115058)})],1)],1),_c('v-divider',{staticClass:"my-4"}),_c('h3',{staticClass:"mb-3"},[_vm._v(" Reasons to Visit ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('p',{class:(errors.length > 0 ? 'error--text' : '') +
                        ' btn-group-label mb-0'},[_vm._v(" Scenery / Landscape ")]),_c('v-btn-toggle',{staticClass:"my-2",attrs:{"rounded":"","dense":"","color":"secondary","error-messages":errors},model:{value:(_vm.airfield.scenery),callback:function ($$v) {_vm.$set(_vm.airfield, "scenery", $$v)},expression:"airfield.scenery"}},_vm._l((_vm.sceneryRatings),function(value,i){return _c('v-btn',{key:value,class:i === 0
                          ? 'ps-3'
                          : i === _vm.sceneryRatings.length - 1
                            ? 'pe-3'
                            : '',attrs:{"value":i + 1}},[_vm._v(" "+_vm._s(value)+" ")])}),1),(errors.length > 0)?_c('div',{staticClass:"v-messages error--text",attrs:{"role":"alert"}},_vm._l((errors),function(error,i){return _c('div',{key:i,staticClass:"v-messages__message"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()]}}],null,false,1541551879)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('p',{class:(errors.length > 0 ? 'error--text' : '') +
                        ' btn-group-label mb-0'},[_vm._v(" Busyness ")]),_c('v-btn-toggle',{staticClass:"my-2",attrs:{"rounded":"","dense":"","color":"secondary","error-messages":errors},model:{value:(_vm.airfield.busyness),callback:function ($$v) {_vm.$set(_vm.airfield, "busyness", $$v)},expression:"airfield.busyness"}},_vm._l((_vm.busynessRatings),function(value,i){return _c('v-btn',{key:value,class:i === 0
                          ? 'ps-3'
                          : i === _vm.busynessRatings.length - 1
                            ? 'pe-3'
                            : '',attrs:{"value":i + 1}},[_vm._v(" "+_vm._s(value)+" ")])}),1),(errors.length > 0)?_c('div',{staticClass:"v-messages error--text",attrs:{"role":"alert"}},_vm._l((errors),function(error,i){return _c('div',{key:i,staticClass:"v-messages__message"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_c('div',{staticClass:"v-messages"},[_c('div',{staticClass:"v-messages__message"},[_vm._v(" On a typical decent flying weather day. ")])])]}}],null,false,1724109389)})],1)],1),_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Things to see","hint":"Optional - unique things or attractions to see here, either from the air, or on the ground.","persistent-hint":"","error-messages":errors},model:{value:(_vm.airfield.reasonsToVisit),callback:function ($$v) {_vm.$set(_vm.airfield, "reasonsToVisit", $$v)},expression:"airfield.reasonsToVisit"}})]}}],null,false,3244200909)})],1)],1),_c('v-divider',{staticClass:"my-4"}),_c('v-row',{staticClass:"mt-4 mb-1",attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"color":"primary","rounded":"","type":"submit","loading":_vm.isSubmitting}},[_vm._v(" Send ")])],1)],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }